/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { showToast } from 'components/toast'
import { IValidation } from '../constants'
import { regexPatterns } from 'sharedKernel/regex'

export function buildValidations(validations: IValidation[] | undefined): string {
  if (validations === undefined || validations.length === 0) {
    return ''
  }
  const checks: string[] = ['validate']

  validations.map((validate) => {
    if (validate.param === undefined || validate.param === null) {
      attachValidationStage(checks, validate.type, validate.stage)
      return
    }
    attachValidationStage(checks, `${validate.type}-${validate.param}`, validate.stage)
  })

  return checks.join(' ')
}

export function buildValidationStage(validation: string, stage?: string, returnWhenAll = true) {
  if (stage === undefined || stage === '' || stage === 'all') {
    return returnWhenAll ? `${validation}` : ''
  }
  return `${stage}.${validation}`
}

export function attachValidationStage(checks: string[], validation: string, stage?: string) {
  checks.push(`${validation}`)
  if (stage === undefined || stage === '' || stage === 'all') {
    return
  }
  checks.push(buildValidationStage(validation, stage, false))
}

function handleErrorClassName(add: boolean, field?: any): void {
  if (field === undefined) {
    return
  }

  field.className = field.className.split('is-invalid').join('').trim()
  if (add) {
    field.className = `${field.className} is-invalid`
  }
}

function handlerErrors(id: string, errors: string[], field?: any): void {
  const spanError = document.getElementsByClassName(`errors-${id}`)
  if (spanError.length <= 0) {
    return
  }

  handleErrorClassName(false, field)
  spanError[0].textContent = ''
  if (errors.length > 0) {
    handleErrorClassName(true, field)
    spanError[0].textContent = errors.join(' | ')
  }
}

const hasLetter = (value: string): boolean => {
  return regexPatterns.hasLetter.test(value)
}

const hasRequiredQuestion = (groupName: string): boolean => {
  let anyRequiredQuestion = false
  const sectionSwitches: any[] = []
  Array.from(document.getElementsByClassName(`${groupName} switch`)).forEach(question => sectionSwitches.push(question.getElementsByTagName('input')))
  sectionSwitches.forEach(sectionSwitch => {
    if (sectionSwitch.isRequired.checked) {
      anyRequiredQuestion = true
    }
  })

  return anyRequiredQuestion
}
const hasCriterion = (sectionId: string): boolean => {
  const element = document.getElementsByClassName(`${sectionId}-field`)
  if (element.length !== 0) {
    return true
  }
  return false
}

const handleDynamicFieldOptionsRequired = (
  options: HTMLCollectionOf<Element>,
  groupName: string,
  stage: string
): boolean => {
  if (options.length < 1) {
    handlerErrors(`${groupName}-description`, ['Insira ao menos uma opção'])
    return false
  }
  for (let x = 0; x < options.length; x++) {
    handleValidation(options[x] as HTMLInputElement, stage)
  }
  return true
}

function validateGroup(groupName: string, className: string, stage: string, isValid: boolean = true): boolean {
  const validations = className.replace(`validateGroup-${groupName} `, '')
  validations.split(' ').map((validation: string) => {
    if (validation === 'dynamicField') {
      const selector = document.getElementsByClassName(`validateGroup-${groupName} selector`)[0] as HTMLSelectElement
      const question = document.getElementsByClassName(`validateGroup-${groupName} question`)[0] as HTMLInputElement
      const options = document.getElementsByClassName(`validateGroup-${groupName} option`)
      const size = document.getElementsByClassName(`validateGroup-${groupName} size`)[0] as HTMLInputElement
      handlerErrors(`${groupName}-question`, [], selector)
      handlerErrors(`${groupName}-size`, [], size)
      handlerErrors(`${groupName}-option`, [])
      if (question.value === undefined || question.value === '') {
        isValid = false
        handlerErrors(`${groupName}-question`, ['Campo obrigatório'], question)
      } else if (!hasLetter(question.value)) {
        handlerErrors(`${groupName}-question`, ['O campo deve conter ao menos uma letra'], question)
      }
      if (selector != null) {
        switch (selector.value) {
          case 'text':
            if (size === undefined || size.value === undefined || size.value === '') {
              isValid = false
              handlerErrors(`${groupName}-size`, ['size required'], size)
            }
            break
          case 'select':
            isValid = handleDynamicFieldOptionsRequired(options, groupName, stage)
            break
          case 'radio-group':
            isValid = handleDynamicFieldOptionsRequired(options, groupName, stage)
            break
          case 'checkbox-group':
            isValid = handleDynamicFieldOptionsRequired(options, groupName, stage)
            break
          case 'checkbox':
            if (options.length <= 0) {
              isValid = false
              handlerErrors(`${groupName}-option`, ['options required'])
              break
            }
            for (let x = 0; x < options.length; x++) {
              handleValidation(options[x] as HTMLInputElement, stage, isValid)
            }
        }
      }
    }
  })
  return isValid
}

function handleValidation(field: any, stage: string, isValid: boolean = true): boolean {
  const errors: string[] = []
  let showErrors = true

  if (field.isDeleted) {
    return isValid
  }

  field.className.split(' ').map((className: string) => {
    if (className === buildValidationStage('required', stage)) {
      if (field.value === undefined || field.value.trim() === '') {
        isValid = false
        errors.push('Campo obrigatório')
      }
    }

    const classGroup = className.split('-')
    const validation = classGroup[0]
    const value = classGroup[1]

    if (validation === buildValidationStage('maxLength', stage)) {
      if (field.value.length > value) {
        isValid = false
        errors.push('Quantidade máxima de caracteres atingida')
      }
    }

    if (className === buildValidationStage('letterRequired', stage)) {
      if (!hasLetter(field.value)) {
        isValid = false
        errors.push('O campo deve conter ao menos uma letra')
      }
    }

    if (validation === buildValidationStage('requiredQuestion', stage)) {
      if (!hasRequiredQuestion(className)) {
        isValid = false
        errors.push('A seção deve conter ao menos uma pergunta obrigatória')
      }
    }

    if (validation === buildValidationStage('requiredField', stage)) {
      if (!hasCriterion(className)) {
        isValid = false
        errors.push('A seção deve conter ao menos um critério')
      }
    }

    if (validation === buildValidationStage('minLength', stage)) {
      if (field.value.trim().length < value) {
        isValid = false
        errors.push(`Quantidade mínima de caracteres não atingida, digite mais ${(parseInt(value) - field.value.trim().length).toString()} caracteres`)
      }
    }

    if (validation === buildValidationStage('pattern', stage)) {
      const regex =
        // eslint-disable-next-line no-useless-escape
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g
      if (!regex.test(field.value) && field.value.trim() !== '') {
        isValid = false
        errors.push('Link inválido')
      }
    }

    if (validation === buildValidationStage('validateGroup', stage) && !field.classList.contains('option')) {
      isValid = validateGroup(value, field.className, stage, isValid)
      showErrors = false
    }
  })

  if (showErrors) {
    handlerErrors(field.id, errors, field)
  }

  return isValid
}

const showErrorToast = (element: any): void => {
  const toastMessage = 'Atenção: O campo \'{}\' está inválido.'

  const elementoLabel = document.querySelector(`label.form-label[for="${element.id}"]`)
  if (elementoLabel) {
    const elementoSpan = elementoLabel.querySelector('span')
    if (elementoSpan) {
      const textSpan = elementoSpan.textContent?.toString().replace(/ \*/g, '')
      if (textSpan) {
        showToast('error', toastMessage.replace('{}', textSpan))
      }
    }
  }
}

function validateAll(stage: string): boolean {
  const elements = document.getElementsByClassName('validate')
  let isValid = true
  for (const element of elements) {
    const noError = handleValidation(element, stage, isValid)
    if (!noError) {
      showErrorToast(element)
      isValid = false
    }
  }
  return isValid
}

export { validateAll, validateGroup, handleValidation }
